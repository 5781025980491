import { Box,useMediaQuery } from '@mui/material';

const About = () => {
    const isSmallScreen = useMediaQuery("(max-width:414px)");
    const mainFontSize = isSmallScreen ? '3rem' : '100px';
    return (
        <Box
            sx={{
                position: 'relative',
            }}>

            {/* MainText */}
            <Box sx={{
                fontSize: mainFontSize,
                textAlign: 'center',
                color: '#FFFFFF',
                fontFamily:'Koulen',
                lineHeight:'0.8',
            }}>
               CONTACT
            </Box>
        </Box>
    );
};

export default About;