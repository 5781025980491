import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import ArticleCard from '../Components/Home/Body/News/ArticleCard';

export default function ImgCarousel() {
    return (
        <Carousel
            showThumbs={false}  
            showStatus={false}  
            showIndicators={true} 
            showArrows={true} 
            infiniteLoop={true}  
            autoPlay={true}  
            interval={3000}  
        >
            <div>
                <ArticleCard />
            </div>
            <div>
                <ArticleCard />
            </div>
            <div>
                <ArticleCard />
            </div>
        </Carousel>
    );
}
