import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import sludge from '../../../../assets/img/sludgeA.webp';
import { useMediaQuery } from '@mui/material';
import ScrollPaper from '../../../ScrollPaper';

export default function ArticleB() {
  const isSmallScreen = useMediaQuery("(max-width:414px)");
  const imgWidth = isSmallScreen ? '200px' : '700px';
  const imgHeight = isSmallScreen ? '30vh' : '500px';
  const mainTextWidth = isSmallScreen ? 'none' : 'none';
  const subTextWidth = isSmallScreen ? 'none' : '80%';
  const textHeight = isSmallScreen ? '150px' : '';
  const resTitleFontSize = isSmallScreen ? '16px' : '24px';
  const resSubFontSize = isSmallScreen ? '12px' : '14px';
  const imgMargin = isSmallScreen ? '10px' : '100px';
  const imgMarginBottom = isSmallScreen ? '10px' : '100px';
  const imgMarginTop = isSmallScreen ? '30px' : '100px';

  const titleText = '活性汚泥とは？';
  const subText =
    '活性汚泥とは、汚水処理において、汚水中の有機物を分解する微生物の塊です活性汚泥とは、汚水処理において、汚水中の有機物を分解する微生物の塊です活性汚泥とは、汚水処理において、汚水中の有機物を分解する微生物の塊です。';
  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'row-reverse',
        backgroundColor: '#6A6A6A',
        marginTop: imgMarginTop,
      }}>
      <CardMedia
        sx={{
          width: imgWidth,
          height: imgHeight,
          marginRight: imgMargin,
          marginBottom: imgMarginBottom
        }}
        image={sludge}
        title="sludgeA"
      />
      <CardContent
        sx={{
          width: '50%',
          display: 'flex',
          flexDirection: 'column'
        }}>
        <Typography
          sx={{
            width: mainTextWidth,
            marginLeft: 'auto',
            marginRight: 'auto',
            color: '#FFFFFF',
            fontSize: resTitleFontSize
          }}
          gutterBottom variant="h5"
          component="div">
          {titleText}
        </Typography>

        <Typography sx={{
          width: subTextWidth,
          marginLeft: 'auto',
          marginRight: 'auto',
          color: '#FFFFFF',
          height: textHeight,
          fontSize: resSubFontSize,
          lineHeight: 1.5,
        }}
          variant="body2"
          color="text.secondary">
          {subText}
        </Typography>

        <ScrollPaper
          titleText={titleText}
          subText={subText}
        >
          <Button>
            Learn More
          </Button>
          </ScrollPaper>
      </CardContent>
    </Card>
  );
}