import { Box, CardMedia, useMediaQuery } from '@mui/material';
import FadeIn from '../../FadeIn';
import stable2 from '../../../assets/img/stable2.webp';
import witchC from '../../../assets/img/witchC.webp';
import '../../../style.css'

const MainText = () => {
    const isSmallScreen = useMediaQuery("(max-width:414px)");
    const mainFontSize = isSmallScreen ? '30px' : '100px';
    const sideFontSize = isSmallScreen ? '15px' : '20px';
    const topImgHeight = isSmallScreen ? '500px' : '800px';
    const backImgHeight = isSmallScreen ? '500px' : '900px';
    return (
        <Box>
            <Box
                sx={{
                    position: 'relative',
                    backgroundColor: '#6A6A6A',
                    height: backImgHeight,
                }}>

                {/* MainImg */}
                <CardMedia
                    component='img'
                    image={stable2}
                    alt='MyIcon'
                    style={{ height: topImgHeight, }}
                />

                {/* IconImg */}
                <CardMedia
                    component='img'
                    image={witchC}
                    alt='MyIcon'
                    style={{
                        width: '100px',
                        left: '50%',
                        top: '25%',
                        transform: 'translate(-50%, -50%)',
                        position: 'absolute',
                    }}
                />

                {/* MainText */}
                <Box className='koulen'
                    sx={{
                        fontSize: mainFontSize,
                        textAlign: 'center',
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        color: 'white',
                        zIndex: 1,
                        textShadow: '30px 30px 3px rgba(0, 0, 0, 0.5)',
                    }}>
                    <FadeIn
                        text='ACTIVATEDSLUDGE.PAGES.DEV'
                    />
                </Box>

                {/* SideText */}
                <Box sx={{
                    color: 'white',
                    fontSize: sideFontSize,
                    position: 'absolute',
                    top: '65%',
                    left: '10%',
                    textAlign: 'left',
                    fontFamily: 'Zen Old Mincho',
                    textShadow: '2px 2px 3px rgba(0, 0, 0, 1.0)',
                }}>
                    <FadeIn text='環境を大切にすることが、' />
                    <FadeIn text='私たちの未来への' />
                    <FadeIn text='最上の努力か。' />
                </Box>
            </Box>
        </Box>
    );
};

export default MainText;
