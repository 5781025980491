import NewsTitle from './News/Title';
import Carousel from '../../Carousel';

function News() {
  return (
    <div>
      <NewsTitle />
      <Carousel/>
    </div>
  );
}

export default News;