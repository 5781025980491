
import HeaderTab from './Header/HeaderTabN';

function Header() {
  return (
    <div>
      <HeaderTab/>
    </div>
  );
}

export default Header;