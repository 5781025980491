import FooterTitle from './Footer/Title';
import FooterLink from './Footer/Link';

function Footer() {
  return (
    <div style={{ backgroundColor: '#3A3A3A' }}>
      <FooterTitle />
      <FooterLink/>
    </div>
  );
}

export default Footer;