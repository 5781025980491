import Header from '../Components/Home/HeaderComp';
import MainText from '../Components/Home/Top/MainText';
import About from '../Components/Home/Body/AboutComp';
import Mission from '../Components/Home/Body/MissionComp';
import News from '../Components/Home/Body/NewsComp';
import Reserch from '../Components/Home/Body/ResearchComp';
import Footer from '../Components/Home/FooterComp';

function Home() {
  return (
    <div>
      <Header />
      <MainText />
      <About />
      <Mission />
      <News />
      <Reserch />
      <Footer />
    </div>
  );
}

export default Home;