import AboutTitle from './About/Title';
import AboutArticleA from './About/ArticleA';
import AboutArticleB from './About/ArticleB';
import AboutArticleC from './About/ArticleC';

function About() {
  return (
    <div >
      <AboutTitle />
      <AboutArticleA/>
      <AboutArticleB/>
      <AboutArticleC/>
    </div>
  );
}

export default About;