import React from 'react';
import { Box, MenuItem, Typography } from '@mui/material';

const NavigationMenuItems = ({ pages }) => {
  return (
    <>
      <Box >
        {pages.map((page) => {
          return ((page.link !== window.location.pathname) &&
            <MenuItem key={page.name} to={page.link} >
              <Typography fontSize={"30px"} textAlign="center" style={{ marginTop: "10px" }} fontFamily={"sans-serif"} sx={{ color: "gray" }}>{page.name}</Typography>
            </MenuItem>)
        })}
      </Box>
    </>
  );
};

export default NavigationMenuItems;
