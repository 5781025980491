// ArticleCard.js
import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import sludge from '../../../../assets/img/sludgeA.webp';
import Box from '@mui/material/Box';
import { useMediaQuery } from '@mui/material';

export default function ArticleCard() {
  const isSmallScreen = useMediaQuery("(max-width:414px)");
  const resMarginSide = isSmallScreen ? '5%' : '20%';
  const resCardHeight = isSmallScreen ? '300px' : '500px';

  return (
    <Box>
      <Card sx={{
        marginLeft: resMarginSide,
        marginRight: resMarginSide,
      }}>
        <CardMedia
          sx={{height: resCardHeight }}
          image={sludge}
          title="sludge"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            Lizard
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Lizards are a widespread group of squamate reptiles, with over 6,000
            species, ranging across all continents except Antarctica
          </Typography>
        </CardContent>
        <CardActions>
          <Button size="small">Share</Button>
          <Button size="small">Learn More</Button>
        </CardActions>
      </Card>
    </Box>
  );
}
