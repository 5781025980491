import { Box, CardMedia, useMediaQuery } from '@mui/material';
import witchC from '../../../assets/img/witchC.webp';

const About = () => {
    const isSmallScreen = useMediaQuery("(max-width:414px)");
    const linkFontSize = isSmallScreen ? '1rem' : '25px';
    const iconSize = isSmallScreen ? '50px' : '80px';
    return (
        <Box
            sx={{
                position: 'relative',
            }}>
            {/* subText */}
            <Box sx={{
                fontSize: linkFontSize,
                textAlign: 'center',
                color: '#FFFFFF',
                fontFamily: 'Zen Old Mincho',
                marginTop: '30px',
            }}>
                mail:kontikuwa@nanntoka
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '30px',
                }}>
                <CardMedia
                    component='img'
                    image={witchC}
                    alt='MyIcon'
                    style={{
                        width: '50px',
                    }}
                />
                <Box sx={{
                    fontSize: linkFontSize,
                    textAlign: 'center',
                    color: '#FFFFFF',
                    fontFamily: 'Zen Old Mincho'
                }}>
                    @testtesttest
                </Box>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                }}>
                <CardMedia
                    component='img'
                    image={witchC}
                    alt='MyIcon'
                    style={{
                        width: '50px',
                        marginBottom: '30px',
                    }}
                />
                <Box sx={{
                    fontSize: linkFontSize,
                    marginBottom: '30px',
                    textAlign: 'center',
                    color: '#FFFFFF',
                    fontFamily: 'Zen Old Mincho'
                }}>
                    @testtesttest
                </Box>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'right'
                }}>
                <CardMedia
                    component='img'
                    image={witchC}
                    alt='MyIcon'
                    style={{
                        width: iconSize,
                    }}
                />
                <CardMedia
                    component='img'
                    image={witchC}
                    alt='MyIcon'
                    style={{
                        width: iconSize,
                        marginRight: '15%',
                    }}
                />
            </Box>
        </Box>
    );
};

export default About;