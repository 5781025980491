import { Box,useMediaQuery } from '@mui/material';
import SlideIn from '../../../SlideIn';

const AboutTitle = () => {
    const isSmallScreen = useMediaQuery("(max-width:414px)");
    const mainFontSize = isSmallScreen ? '60px' : '120px';
    return (
        <Box
            sx={{
                position: 'relative',
            }}>

            {/* MainText */}
            <Box sx={{
                fontSize: mainFontSize,
                fontFamily: 'Koulen',
                lineHeight: 0.8,
            }}>
                <SlideIn text='ABOUT' />
            </Box>

            {/* subText */}
            <Box sx={{
                fontSize: '30px',
                marginBottom: '5%',
                fontFamily: 'Zen Old Mincho',
            }}>
                 <SlideIn text='活性汚泥とは？' />
            </Box>
        </Box>
    );
};

export default AboutTitle;