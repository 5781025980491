import { Box,useMediaQuery } from '@mui/material';
import SlideIn from '../../../SlideIn';

const Reserch = () => {
    const isSmallScreen = useMediaQuery("(max-width:414px)");
    const mainFontSize = isSmallScreen ? '60px' : '120px';
    return (
        <Box
            sx={{
                position: 'relative',
            }}>

            {/* MainText */}
            <Box sx={{
                fontSize: mainFontSize,
                marginTop: '20%',
                textAlign: 'right',
                color: '#FFFFFF',
                fontFamily:'Koulen',
                lineHeight: 0.8,
            }}>
                <SlideIn text='RESEARCH' />
            </Box>

            {/* subText */}
            <Box sx={{
                fontSize: '30px',
                marginBottom: '10%',
                textAlign: 'right',
                color: '#FFFFFF',
                fontFamily:'Zen Old Mincho'
            }}>
                <SlideIn text='調査報告'/>   
            </Box>
        </Box>
    );
};

export default Reserch;