import ResearchTitle from './Research/Title';
import ResearchArticleA from './Research/ArticleA';
import ResearchArticleB from './Research/ArticleB';

function Research() {
    return (
        <div style={{ backgroundColor: '#6A6A6A' }}>
            <ResearchTitle />
            <ResearchArticleA />
            <ResearchArticleB />
        </div>
    );
}

export default Research;