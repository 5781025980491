import MissionTitle from './Mission/Title';
import MissionArticleA from './Mission/ArticleA';
import MissionArticleB from './Mission/ArticleB';
import { Box } from '@mui/material';

function About() {
  return (
    <Box style={{ backgroundColor: '#6A6A6A', }}>
      <MissionTitle />
      <MissionArticleA />
      <MissionArticleB />
    </Box>
  );
}

export default About;