import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const SlideIn = ({ text }) => {
    let textRefs = useRef([]);

    useEffect(() => {
        gsap.fromTo(
            textRefs.current,
            { x: 0, opacity: 0 },
            {
                x: 0,
                opacity: 1,
                stagger: 2,
                duration: 2,
                scrollTrigger: {
                    trigger: textRefs.current[0],
                    start: "top center",
                    end: "bottom center-=15%",
                    scrub: true,
                },
            }
        );
    }, []);

    return (
        <div>
            {text.split('').map((char, index) => (
                <span
                    key={index}
                    ref={(el) => (textRefs.current[index] = el)}
                    style={{ display: 'inline-block' }}
                >
                    {char}
                </span>
            ))}
        </div>
    );
};

export default SlideIn;
