import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import sludge from '../../../../assets/img/sludgeA.webp';
import { useMediaQuery } from '@mui/material';

export default function MissionArticleA() {
  const isSmallScreen = useMediaQuery("(max-width:414px)");
  const resMarginTop = isSmallScreen ? '' : '100px';
  const resWidth = isSmallScreen ? '80%' : '1000px';
  const resHeight = isSmallScreen ? '400px' : '700px';
  const resImgHeight = isSmallScreen ? '200px' : '500px';
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
      <Card sx={{ maxWidth: resWidth, height: resHeight, marginTop: resMarginTop,marginBottom:'100px' }}>
        <CardMedia
          sx={{ height: resImgHeight,}}
          image={sludge}
          title="sludge"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            Lizard
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Lizards are a widespread group of squamate reptiles, with over 6,000
            species, ranging across all continents except Antarctica
          </Typography>
        </CardContent>
        <CardActions>
          <Button size="small">Share</Button>
          <Button size="small">Learn More</Button>
        </CardActions>
      </Card>
    </div>
  );
}