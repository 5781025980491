import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import sludge from '../../../../assets/img/sludgeA.webp';
import { useMediaQuery } from '@mui/material';

export default function ArticleA() {
  const isSmallScreen = useMediaQuery("(max-width:414px)");
  const resTitleFontSize = isSmallScreen ? '16px' : '24px';
  const resSubFontSize = isSmallScreen ? '12px' : '16px';
  const imgWidth = isSmallScreen ? '300px' : '600px';
  const imgHeight = isSmallScreen ? '300px' : '400px';
  return (
    <Card sx={{ display: 'flex', backgroundColor: '#D2D2D2', marginBottom: '10%' }}>
      <CardMedia
        sx={{ width: imgWidth, height: imgHeight, marginBottom: '50px', marginLeft: '30px' }}
        image={sludge}
        title="sludgeA"
      />
      <CardContent sx={{ width: '50%', display: 'flex', flexDirection: 'column' }}>
        <Typography sx={{ fontSize: resTitleFontSize }} gutterBottom variant="h5" component="div">
          活性汚泥とは？
        </Typography>
        <Typography sx={{ fontSize: resSubFontSize }} variant="body2" color="text.secondary">
          下水処理で有機物などを分解し、浄化する役割を果たしている好気性微生物群のことを指す。下水処理で有機物などを分解し、浄化する役割を果たしている好気性微生物群のことを指す。下水処理で有機物などを分解し、浄化する役割を果たしている好気性微生物群のことを指す。下水処理で有機物などを分解し、浄化する役割を果たしている好気性微生物群のことを指す。
        </Typography>
        <CardActions sx={{ mt: 'auto' }}>
          <Button size="small">Learn More</Button>
        </CardActions>
      </CardContent>
    </Card>
  );
}