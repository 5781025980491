import { Box, useMediaQuery } from '@mui/material';
import SlideIn from '../../../SlideIn';

const About = () => {
    const isSmallScreen = useMediaQuery("(max-width:414px)");
    const mainFontSize = isSmallScreen ? '60px' : '120px';
    return (
        <Box
            sx={{
                height: '100px',
            }}>

            {/* MainText */}
            <Box sx={{
                fontSize: mainFontSize,
                textAlign: 'right',
                color: '#FFFFFF',
                fontFamily: 'Koulen',
                lineHeight: 0.8,
            }}>
                <Box >
                    <SlideIn text='MISSION' />
                </Box>
            </Box>

            {/* subText */}
            <Box sx={{
                fontSize: '30px',
                marginBottom: '10%',
                textAlign: 'right',
                color: '#FFFFFF',
                fontFamily: 'Zen Old Mincho',
            }}>
                <SlideIn text='私たちの使命' />
            </Box>
        </Box>
    );
};

export default About;